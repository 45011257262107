<template>
  <div class="members-profile">
    <v-container class="first-part">
      <Breadcrumbs />

      <v-row>
        <v-col class="d-flex pb-0">
          <v-icon
            large
            :color="
              this.$store.getters['auth/user']
                ? this.$store.getters['auth/user'].theme_config.primary_color
                : ''
            "
            >mdi-account-question</v-icon
          >
          <h1 class="d-flex ml-2 page-title pt-3">
            {{ $t("questionnaires") }}
          </h1>
        </v-col>
      </v-row>

      <v-row
        :class="`basic-info mt-12 pl-2 ${isLifeChamps ? 'mb-8' : 'mb-16'} `"
        v-if="member"
      >
        <template v-if="!isLifeChamps">
          <v-col class="card pt-0" cols="1">
            <p class="card-title mb-2">{{ $t("id") }}</p>
            <p class="card-body"></p>
          </v-col>
          <v-col class="card pt-0" cols="2">
            <p class="card-title mb-2">{{ $t("insurance_number") }}</p>
            <p class="card-body">
              {{ this.member.insurance_number }}
            </p>
          </v-col>
          <v-col class="card pt-0" cols="1">
            <p class="card-title mb-2">{{ $t("gender") }}</p>
            <p class="card-body">
              {{ this.member.gender == 1 ? $t("male") : "" }}
            </p>
            <p class="card-body">
              {{ this.member.gender == 2 ? $t("female") : "" }}
            </p>
          </v-col>
          <v-col class="card pt-0" cols="1">
            <p class="card-title mb-2">{{ $t("age") }}</p>
            <p class="card-body">{{ this.member.age }}</p>
          </v-col>
        </template>
      </v-row>
    </v-container>

    <v-container fluid class="medical-info-container">
      <v-container>
        <v-tabs v-model="tab" align-with-title class="mb-8">
          <v-tabs-slider
            :color="
              $store.getters['auth/user']
                ? $store.getters['auth/user'].theme_config.primary_color
                : ''
            "
          ></v-tabs-slider>

          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="pl-5">
          <v-tab-item v-for="item in items" :key="item">
            <v-container v-if="tab == 0">
              <template v-for="questionnairesDetail in questionnairesDetails">
                <v-expansion-panels
                  :key="questionnairesDetail.id"
                  class="justify-start mb-10"
                  v-if="questionnairesDetail.pairs.length != 0"
                >
                  <h3 class="mb-3 float-left">
                    {{ $t("questionnaire") }}: {{ questionnairesDetail.title }}
                  </h3>
                  <v-expansion-panel
                    v-for="(pair, index) in questionnairesDetail.pairs"
                    :key="index"
                  >
                    <v-expansion-panel-header>
                      {{ index }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left text-capitalize">
                                {{ $t("question") }}
                              </th>
                              <th class="text-left text-capitalize">
                                {{ $t("answer") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="question in pair" :key="question.id">
                              <td>{{ question.question }}</td>
                              <td>{{ question.answer }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import { mapActions } from "vuex";

export default {
  name: "detailed-view",

  data() {
    return {
      questionnairesDetails: null,
      tab: null,
      items: [
        this.$t("health"),
        this.$t("patient profile"),
        this.$t("history"),
      ],
    };
  },

  computed: {
    isLifeChamps: function () {
      return (
        this.$store.getters["auth/user"]?.theme_config.primary_color ==
        "#3452FF"
      );
    },
    member: function () {
      return this.$store.getters["members/member"];
    },
  },

  mounted() {
    this.getMember();
    this.getQuestionnairesDetails();
  },

  methods: {
    ...mapActions({
      getMember: "members/getMember",
    }),
    getQuestionnairesDetails() {
      this.$axios
        .get(`dashboard/get-questionnaires-by-user/${this.$route.params.id}`)
        .then((res) => {
          if (Object.keys(res.data.data)[0]) {
            this.questionnairesDetails = res.data.data;
          }
        });
    },
  },

  components: {
    Breadcrumbs,
  },
};
</script>

<style lang="scss" src="@/assets/sass/views/dashboard/members/id.scss">
</style>